import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useGlobalState } from 'state'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import CollapsibleHeader from 'components/CollapsibleHeader'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphMiniSemiBold from 'components/typography/RfParagraph/RfParagraphMiniSemiBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallSemiBold from 'components/typography/RfParagraph/RfParagraphSmallSemiBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import {
  CourseDashboardCourseBlockPartsFragment,
  CourseDashboardCourseSessionPartsFragment,
  CoursePageAccessCreatorFragment,
  CourseSessionAccessPartsFragment,
  LiveCourseDashboardCoursePartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  useContentMode,
  useTrackCourseDashboardContentClicked
} from 'utils/contentTrackingUtils'
import { buildCourseDashboardLinkFromPath } from 'utils/courseUtils'
import { formatDateRange } from 'utils/date'
import { track } from 'utils/tracking/segment'

import { ReactComponent as CheckIcon } from 'images/icon--checkmark-thin.svg'
import { ReactComponent as SlackIcon } from 'images/slack-icon.svg'

interface CourseDashboardHeaderProps {
  id: string
  showBackButton?: boolean
  course: LiveCourseDashboardCoursePartsFragment
  courseBlocks?: CourseDashboardCourseBlockPartsFragment[] | null
  courseSession?:
    | CourseSessionAccessPartsFragment
    | CourseDashboardCourseSessionPartsFragment
    | null
  showOffset?: boolean
  welcomePage?: boolean
}

const CourseDashboardHeader = ({
  id,
  course,
  courseSession,
  courseBlocks,
  showOffset = false,
  welcomePage = false
}: CourseDashboardHeaderProps) => {
  const history = useHistory()
  const [envVariables] = useGlobalState('envVariables')
  const { currentUser } = useCurrentUser()
  const contentMode = useContentMode({ courseSession }) || ''
  const trackCourseDashboardContentClicked = useTrackCourseDashboardContentClicked()

  const formattedDateRange = formatDateRange(
    courseSession?.startsAt,
    courseSession?.endsAt
  )

  const coursePill = () => {
    return (
      <RfParagraphMiniSemiBold className="mb-4 w-fit rounded-full bg-rb-orange-50 px-4 py-2">
        {formattedDateRange}
      </RfParagraphMiniSemiBold>
    )
  }

  const getExperts = (): any[] => {
    if (courseSession?.hosts) {
      return courseSession.hosts || []
    }
    return course.creators || []
  }

  const courseTitle = () => (
    <RfHeader2>
      <span className="my-auto font-normal">{course.title}</span>
    </RfHeader2>
  )

  const onNextGuideClick = () => {
    let nextGuide = courseBlocks?.[0]?.units?.[0]
    let nextCourseBlock = courseBlocks?.[0]
    let foundNextGuide = false

    courseBlocks?.forEach((block) => {
      const nextUnit = block?.units?.find((unit) => !unit.completed)
      if (nextUnit && !foundNextGuide) {
        nextGuide = nextUnit
        nextCourseBlock = block
        foundNextGuide = true
      }
    })

    const destination = buildCourseDashboardLinkFromPath({
      courseSlug: course.slug,
      sessionId: courseSession?.id,
      guideSlug: nextGuide?.slug
    })

    if (nextGuide && nextCourseBlock) {
      trackCourseDashboardContentClicked({
        destination,
        contentType: 'guide',
        contentTitle: nextGuide?.title || '',
        contentSanityId: nextGuide?.id || '',
        contentCclLocalId: nextGuide?.guide?.id,
        course,
        courseSession,
        courseBlock: nextCourseBlock,
        contentMode
      })

      history.push(destination)
    }
  }

  const hasStartedCourse = useMemo(() => {
    return courseBlocks?.flatMap((block) => block?.units).some((unit) => unit?.completed)
  }, [courseBlocks])

  const isCourseCompleted = useMemo(() => {
    return courseBlocks?.flatMap((block) => block?.units).every((unit) => unit?.completed)
  }, [courseBlocks])

  const courseSubtitle = () => {
    const experts = getExperts()
    return (
      <RfParagraphSmall className="my-auto mb-4" color={ColorOptions.gray400}>
        with{' '}
        {experts.map((expert: any, index: number) => (
          <span key={expert.id}>
            {expert.name}
            {experts?.length && experts.length - 1 === index ? '' : ' and '}
          </span>
        ))}
      </RfParagraphSmall>
    )
  }

  const openSlack = () => {
    // @ts-ignore - 'Slack - Action' event is not defined in Segment
    track('Slack - Action', {
      action: 'go_to_slack_channel_clicked',
      location: 'course_page',
      related_identifiers: {
        course_id: course.id,
        course_title: course.title,
        course_session_id: courseSession?.id
      }
    })

    window.open(
      currentUser?.slackUserId
        ? `https://reforge-members.slack.com/app_redirect?channel=${courseSession?.slackChannelId}`
        : envVariables.joinSlackHref
    )
  }

  const slackButton = ({ className }: { className: string }) => (
    <Button
      variant="text-only"
      onClick={openSlack}
      className={twMerge('py-1.5 normal-case', className)}
      size="x-small"
    >
      <SlackIcon className="mr-2 h-[16px] w-[16px]" />
      Course Slack
    </Button>
  )

  const courseProgress = ({ className }: { className?: string }) => {
    return (
      <div className={twMerge('flex items-center gap-4', className)}>
        <div className="w-[218px] bg-[#E3CCBD4D] rounded h-[5px] overflow-hidden">
          <div
            className={twMerge(
              'bg-rb-success-100 h-full',
              course.completionPercentage === 100 && 'rounded-r-full'
            )}
            style={{ width: `${course.completionPercentage}%` }}
          />
        </div>

        <RfParagraphSmallSemiBold color={ColorOptions.success200}>
          {course.completionPercentage}%
        </RfParagraphSmallSemiBold>
      </div>
    )
  }

  const collapsedHeader = () => (
    <div
      id="course-dashboard-header-collapsed"
      className="flex flex-col items-center bg-rb-orange-25 px-7 py-4 lg:gap-4"
    >
      <div
        className={twMerge(
          'flex w-full flex-col items-center',
          showOffset && 'max-w-[820px] lg:items-end'
        )}
      >
        <div className="flex w-full max-w-[600px] flex-1 flex-col justify-between gap-2 sm:flex-row sm:items-center">
          <div className="flex flex-col flex-1">
            {courseTitle()}
            <div className="hidden md:block">{courseSubtitle()}</div>
            {courseProgress({})}
          </div>

          <div className="flex items-center flex-col gap-1">
            {!welcomePage && (
              <Button onClick={onNextGuideClick} size="small">
                {hasStartedCourse ? 'Continue' : 'Start Course'}
              </Button>
            )}
            {courseSession?.slackChannelId && slackButton({ className: 'my-auto' })}
          </div>
        </div>
      </div>
    </div>
  )

  const uncollapsedHeader = () => (
    <div className="flex flex-col items-center bg-rb-orange-25 px-7 py-8 sm:py-16 lg:gap-4">
      <div
        className={twMerge(
          'flex w-full flex-col items-center',
          showOffset && 'max-w-[820px] lg:items-end'
        )}
      >
        <div className="flex w-full max-w-[600px] flex-1 items-center justify-between gap-16">
          <div className="w-full">
            {coursePill()}

            {courseTitle()}

            <RfParagraphSmall className="mb-4">
              <span className="text-rb-gray-400">{course.shortDescription}</span>
            </RfParagraphSmall>

            {courseSubtitle()}

            {courseProgress({ className: 'mb-4' })}

            <div className="flex items-center gap-4">
              {!welcomePage && (
                <Button
                  onClick={onNextGuideClick}
                  size="small"
                  disabled={isCourseCompleted}
                >
                  {isCourseCompleted && (
                    <CheckIcon className="h-[16px] w-[16px] text-[#B5B8B4] fill-current mr-2" />
                  )}

                  {isCourseCompleted
                    ? 'Complete'
                    : hasStartedCourse
                      ? 'Continue'
                      : 'Start Course'}
                </Button>
              )}
              {courseSession?.slackChannelId && slackButton({ className: '' })}
            </div>
          </div>

          <div className="hidden w-full max-w-fit flex-col items-center justify-center gap-2 sm:flex">
            {getExperts().map((expert: CoursePageAccessCreatorFragment) => (
              <img
                key={expert.id}
                className={twMerge(
                  'h-full w-full rounded-2xl object-cover',
                  getExperts().length === 1
                    ? 'max-h-[219px] max-w-[219px]'
                    : 'max-h-[125px] max-w-[125px]'
                )}
                alt={expert.avatarPhoto?.imageAlt || ''}
                src={expert.avatarPhoto?.imageUrl || ''}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <CollapsibleHeader
      collapsedHeaderNode={collapsedHeader()}
      uncollapsedHeaderNode={uncollapsedHeader()}
      uncollapsedHeaderId={id}
      uncollapsedOutOfViewportOffset={360}
      collapsedTopOffset="top-0"
    />
  )
}

export default CourseDashboardHeader
