import React from 'react'

import Button from 'components/Button'

import { ReactComponent as DownloadIcon } from 'images/icon--download.svg'
import { ReactComponent as FiltersIcon } from 'images/icon--filters.svg'
import { ReactComponent as SearchIcon } from 'images/p-search.svg'

import DropdownMultiSelect from '../../../components/dropdowns/DropdownMultiSelect/DropdownMultiSelect'

interface AdminTableToolbarProps {
  isMobile: boolean
  searchTerm: string
  setSearchTerm: (term: string) => void
  selectedCoursePass: string[]
  setSelectedCoursePass: (selected: string[]) => void
  selectedEnrollment: string[]
  setSelectedEnrollment: (selected: string[]) => void
  selectedStatus: string[]
  setSelectedStatus: (selected: string[]) => void
  onDownload: () => void
  isFilterExpanded: boolean
  setIsFilterExpanded: (expanded: boolean) => void
  onManageInvites: () => void
}

export const AdminTableToolbar: React.FC<AdminTableToolbarProps> = ({
  isMobile,
  searchTerm,
  setSearchTerm,
  selectedCoursePass,
  setSelectedCoursePass,
  selectedEnrollment,
  setSelectedEnrollment,
  selectedStatus,
  setSelectedStatus,
  onDownload,
  isFilterExpanded,
  setIsFilterExpanded,
  onManageInvites
}) => {
  const toggleFilter = () => setIsFilterExpanded(!isFilterExpanded)

  const getActiveFilterCount = () => {
    let count = 0
    if (selectedCoursePass.length > 0) count++
    if (selectedEnrollment.length > 0) count++
    if (selectedStatus.length > 0) count++
    return count
  }

  return (
    <div className="w-full mb-6 lg:mb-8" data-testid="admin-table-toolbar">
      <div className="flex flex-col gap-4">
        {isMobile ? (
          <>
            <div className="flex flex-col sm:flex-row items-center justify-between gap-2">
              <div className="relative w-full sm:w-auto sm:flex-grow sm:mr-2">
                <input
                  type="text"
                  placeholder="Search name/email"
                  className="w-full pl-4 pr-10 py-2 bg-rb-gray-50 border border-gray-300 rounded-md outline-1 outline-rb-gray-200"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  data-test="seats-list-search-input"
                />
                <SearchIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
              </div>
              <div className="flex w-full sm:w-auto justify-between sm:justify-start items-center">
                <Button
                  variant="outline"
                  onClick={toggleFilter}
                  className={`border ${isFilterExpanded ? 'border-gray-500' : 'border-gray-300'} hover:bg-gray-50 hover:border-gray-500 pl-2 pr-3 h-[40px]`}
                >
                  <FiltersIcon className="w-4 h-4 mr-2" />
                  Filters
                  {getActiveFilterCount() > 0 && (
                    <span className="ml-2 bg-gray-200 text-gray-700 px-2 py-0.5 rounded-full text-xs font-semibold">
                      {getActiveFilterCount()}
                    </span>
                  )}
                </Button>
                <Button
                  variant="outline"
                  className="px-4 py-2 h-[40px] border border-gray-300 hover:bg-gray-50 hover:border-gray-500 px-2 h-[40px]"
                  onClick={onManageInvites}
                >
                  Manage Invites
                </Button>
              </div>
            </div>
            {isFilterExpanded && (
              <div className="flex flex-col gap-2 bg-gray-50 p-4 rounded-md">
                <div className="w-full text-sm font-medium text-gray-700 mb-2">
                  Filters
                </div>
                <DropdownMultiSelect
                  displayText="Status"
                  data={[
                    { label: 'Active', value: 'active' },
                    { label: 'Pending', value: 'pending' },
                    { label: 'Available', value: 'available' }
                  ]}
                  selectedItems={selectedStatus}
                  onSelection={setSelectedStatus}
                  className="h-[40px] w-full"
                  dropdownClassName="w-full"
                />
                <DropdownMultiSelect
                  displayText="Course Pass"
                  data={[
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' }
                  ]}
                  selectedItems={selectedCoursePass}
                  onSelection={setSelectedCoursePass}
                  className="h-[40px] w-full"
                  dropdownClassName="w-full"
                />
                <DropdownMultiSelect
                  displayText="Enrollment"
                  data={[
                    { label: 'Enrolled', value: 'Enrolled' },
                    { label: 'Not Enrolled', value: 'Not Enrolled' }
                  ]}
                  selectedItems={selectedEnrollment}
                  onSelection={setSelectedEnrollment}
                  className="h-[40px] w-full"
                  dropdownClassName="w-full"
                />
              </div>
            )}
          </>
        ) : (
          <>
            {/* Tablet and Desktop view */}
            <div className="flex flex-col gap-4">
              <div className="relative w-[18rem]">
                <input
                  type="text"
                  placeholder="Search name/email"
                  className="w-full pl-4 pr-10 py-2 bg-rb-gray-50 border border-gray-300 rounded-md outline-1 outline-rb-gray-200"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  data-test="seats-list-search-input"
                />
                <SearchIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
              </div>
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-2">
                  <DropdownMultiSelect
                    displayText="Course Pass"
                    data={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' }
                    ]}
                    selectedItems={selectedCoursePass}
                    onSelection={setSelectedCoursePass}
                    className="h-[40px] w-[150px]"
                    dropdownClassName="w-[150px]"
                  />
                  <DropdownMultiSelect
                    displayText="Enrollment"
                    data={[
                      { label: 'Enrolled', value: 'Enrolled' },
                      { label: 'Not Enrolled', value: 'Not Enrolled' }
                    ]}
                    selectedItems={selectedEnrollment}
                    onSelection={setSelectedEnrollment}
                    className="h-[40px] w-[150px]"
                    dropdownClassName="w-[150px]"
                  />
                  <DropdownMultiSelect
                    displayText="Status"
                    data={[
                      { label: 'Active', value: 'active' },
                      { label: 'Pending', value: 'pending' },
                      { label: 'Available', value: 'available' }
                    ]}
                    selectedItems={selectedStatus}
                    onSelection={setSelectedStatus}
                    className="h-[40px] w-[150px]"
                    dropdownClassName="w-[150px]"
                  />
                </div>
                <div className="flex gap-2 ml-4">
                  <Button
                    className="border border-gray-300 hover:bg-gray-50 hover:border-gray-500 px-2 h-[40px] w-[40px]"
                    variant="outline"
                    size="small"
                    aria-label="Download"
                    onClick={onDownload}
                  >
                    <DownloadIcon className="w-5 h-5" />
                  </Button>
                  <Button
                    variant="outline"
                    className="px-4 py-2 h-[40px] border border-gray-300 hover:bg-gray-50 hover:border-gray-500 px-2 h-[40px]"
                    onClick={onManageInvites}
                  >
                    Manage Invites
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
