import { cn } from '@/lib/utils'
import { formatInTimeZone } from 'date-fns-tz'
import { ComponentType, MouseEvent, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { PopoverPosition } from 'react-tiny-popover'

import CourseDetailEnrollmentBadges, {
  CourseAvailabilityBadge
} from 'domains/CourseDetail/CourseDetailEnrollmentBadges'
import Image from 'domains/Sanity/Image'

import Button from 'components/Button'
import { FacePile, FacePileUser } from 'components/FacePile'
import { Tag } from 'components/Tag'
import { CheckmarkBorderlessIcon } from 'components/icons'
import RfParagraphMiniSemiBold from 'components/typography/RfParagraph/RfParagraphMiniSemiBold'

import {
  BookmarkFolderPartsFragment,
  CclCourseCourseCardPartsFragment,
  CclCourseSessionCourseCardPartsFragment,
  CclExpertCourseCardPartsFragment,
  CourseBookmarkPartsFragment,
  ProgramBookmarkPartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useURLParams from 'hooks/useURLParams'

import { getCurrentTimezone, isBeforeDate } from 'utils/date'
import notifyError from 'utils/errorNotifier'
import { listify } from 'utils/stringUtils'
import { trackCtaClicked, trackNavigationClicked } from 'utils/tracking/analytics'

import BaseCard, { CardVariant, CardVariants } from './BaseCard'

export const COURSE_CARD_TYPE_LIVE = 'live'
export const COURSE_CARD_TYPE_ON_DEMAND = 'on-demand'
export const COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS = 'courseDetails'
export const COURSE_CARD_DESTINATION_TYPE_COURSE_DASHBOARD = 'courseDashboard'
export const COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD = 'onDemandDashboard'

export interface CourseCardProps {
  course: Omit<CclCourseCourseCardPartsFragment, 'staticId' | '__typename'>
  courseSession?: CclCourseSessionCourseCardPartsFragment | null
  legacyCourseSessionId?: number | string
  variant?: CardVariant
  styleVariant?:
    | 'default'
    | 'live-featured'
    | 'on-demand-featured'
    | 'logged-out-courses-index'
  showSessionHostInThumbnail?: boolean
  showSessionHostInByline?: boolean
  pageLocation?: string
  locationId?: string
  locationType?: string
  activeTab?: string
  customThumbnail?: ReactNode
  CourseFooterOverride?: ComponentType<any>
  destinationType?:
    | typeof COURSE_CARD_DESTINATION_TYPE_COURSE_DASHBOARD
    | typeof COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS
    | typeof COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
  additionalRelatedIdentifiers?: {}
  // Bookmark-related props
  bookmark?: CourseBookmarkPartsFragment | ProgramBookmarkPartsFragment
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (
    bookmark: CourseBookmarkPartsFragment | ProgramBookmarkPartsFragment
  ) => void
  restoreBookmark?: (
    bookmark: CourseBookmarkPartsFragment | ProgramBookmarkPartsFragment
  ) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  hideBookmarkButton?: boolean
  showFreeWithCoursePass?: boolean
  customHoverMiniCard?: boolean
  impressionTrackingProperties?: { [key: string]: any }
  cardVariant?: CardVariant
  bookmarkDropdownPosition?: PopoverPosition
  sectionIndex?: number
  sectionImpressionIndex?: number
  baseCardClassName?: string
  onCoursesIndexPage?: boolean
}

const CourseCard = ({
  course,
  courseSession,
  legacyCourseSessionId,
  styleVariant = 'default',
  showSessionHostInThumbnail = false,
  showSessionHostInByline = false,
  variant = CardVariants.Vertical,
  destinationType = COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS,
  bookmark,
  currentFolder,
  bookmarkFolders,
  openAddToBookmarkFolderModal,
  restoreBookmark,
  handleRemoveFromFolder,
  hideBookmarkButton = false,
  showFreeWithCoursePass = false,
  pageLocation,
  locationId,
  locationType,
  activeTab,
  additionalRelatedIdentifiers,
  CourseFooterOverride,
  customThumbnail,
  customHoverMiniCard = false,
  impressionTrackingProperties,
  bookmarkDropdownPosition,
  sectionIndex,
  sectionImpressionIndex,
  baseCardClassName,
  onCoursesIndexPage = false
}: CourseCardProps) => {
  const { pathname } = useLocation()
  const { isLoggedIn, currentUser } = useCurrentUser()
  const { showNewCourseGating, showCourseLandingPageUpdates } = useFeatureFlags()

  const courseId = course.id

  const title = course.title
  const slug = course.slug
  const description = course.shortDescription
  const { getParam } = useURLParams()

  const destination = () => {
    const tab = getParam('tab')
    const legacyCourseSlug = course.cmsProgram?.slug

    if (
      legacyCourseSlug &&
      tab === 'on-demand' &&
      !currentUser?.is.freeUser &&
      currentUser?.preferredCourseView === 'legacy'
    ) {
      return `/programs/${legacyCourseSlug}`
    }

    let destinationUrl = `/courses/${slug}`

    if (destinationType === COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS) {
      destinationUrl += '/details'
    }

    if (!isLoggedIn) {
      return destinationUrl
    }

    if (destinationType === COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD) {
      destinationUrl += '/on-demand'
    }

    if (
      destinationType === COURSE_CARD_DESTINATION_TYPE_COURSE_DASHBOARD &&
      legacyCourseSessionId
    ) {
      destinationUrl += `/sessions/${legacyCourseSessionId}`
    }

    return destinationUrl
  }

  const destinationUrl = destination()
  const relatedIdentifiers = {
    course_id: courseId,
    course_title: title,
    course_session_id: courseSession?.id,
    is_featured: false,
    is_filtered_reference: false,
    is_empty_index_results: false,
    has_live_course_available: !!((course as any)?.nextLiveSession || courseSession),
    ...additionalRelatedIdentifiers // use this to override any of the identifiers above
  }

  const handleTracking = () => {
    trackNavigationClicked({
      destination: destinationUrl,
      location: pageLocation,
      location_id: locationId,
      location_type: locationType,
      logged_in: isLoggedIn,
      path: pathname,
      type: `${variant}_card`, // horizontal_card, vertical_card, mini_card, list_card
      related_identifiers: relatedIdentifiers,
      section_index: sectionIndex,
      section_impression_index: sectionImpressionIndex,
      text: course.title.toLowerCase()
    })
  }

  const thumbnailExperts =
    showSessionHostInThumbnail && courseSession?.experts?.length
      ? courseSession.experts
      : course.creators

  const FooterComponent = ({ onCoursesIndexPage }: { onCoursesIndexPage: boolean }) => {
    if (styleVariant === 'live-featured') {
      const courseId = course?.id?.toString()
      const session = (course as any)?.nextLiveSession || courseSession
      const upcomingCourses =
        currentUser?.userCourses?.upcoming?.map((c) => c.id.toString()) ?? []

      return (
        <div className="flex justify-between items-center">
          <span className="text-xs text-rb-gray-300 font-sans font-normal">
            {session?.duration}
          </span>
          {!upcomingCourses.includes(courseId) ? (
            <Button
              variant="outline"
              size="x-small"
              className="py-2 px-4 font-sans font-medium"
            >
              Learn more & enroll
            </Button>
          ) : (
            <Button
              variant="outline"
              size="x-small"
              className="py-2 px-4 font-sans font-medium"
            >
              <div className="h-4 w-4 mr-2">
                <CheckmarkBorderlessIcon />
              </div>
              Enrolled
            </Button>
          )}
        </div>
      )
    }

    if (styleVariant === 'on-demand-featured') {
      return (
        <div className="flex justify-between items-center">
          <span></span>

          <Button
            variant="outline"
            size="x-small"
            className="py-2 px-4 font-sans font-medium"
          >
            Get started
          </Button>
        </div>
      )
    }

    // remove this block completely when removing showCourseLandingPageUpdates feature flag.
    // this is only used in CoursesListOld.tsx
    if (styleVariant === 'logged-out-courses-index') {
      return (
        <div className="flex items-end justify-between">
          {showNewCourseGating ? (
            courseSession && activeTab === 'live' ? (
              <CourseFooter
                course={course}
                courseSession={courseSession}
                timezone={currentUser?.timezone}
                showFreeWithCoursePass={showFreeWithCoursePass}
              />
            ) : (
              <div className="flex flex-row justify-between sm:mr-8">
                <span className="font-semibold text-rb-gray-400">On-demand</span>
              </div>
            )
          ) : (
            <CourseFooter
              course={course}
              courseSession={courseSession}
              timezone={currentUser?.timezone}
              showFreeWithCoursePass={showFreeWithCoursePass}
            />
          )}

          <Button
            size="small"
            className="h-8 md:h-10 px-4 ml-auto"
            onClick={(e: MouseEvent<HTMLButtonElement>) =>
              trackCtaClicked({
                cta_location: 'course_index_card',
                cta_type: 'button',
                text: e.currentTarget.textContent || '',
                destination: destinationUrl,
                logged_in: isLoggedIn,
                related_identifiers: relatedIdentifiers
              })
            }
          >
            {showNewCourseGating
              ? courseSession && activeTab === 'live'
                ? 'Learn more & enroll'
                : 'Get started'
              : 'Learn more'}
          </Button>
        </div>
      )
    }

    if (CourseFooterOverride) {
      return <CourseFooterOverride course={course} />
    }

    return (
      <CourseFooter
        course={course}
        courseSession={courseSession}
        timezone={currentUser?.timezone}
        showFreeWithCoursePass={showFreeWithCoursePass}
        onCoursesIndexPage={onCoursesIndexPage}
      />
    )
  }

  return (
    <BaseCard
      contentType="Course"
      variant={variant}
      sanityId={course.sanityId || course.id}
      title={title}
      className={baseCardClassName}
      titleClassName={cn(
        (styleVariant === 'live-featured' || styleVariant === 'on-demand-featured') &&
          'font-polysans text-lg md:text-2xl font-normal text-rb-gray-400',
        styleVariant === 'default' &&
          showCourseLandingPageUpdates &&
          'text-xl !font-semibold leading-[1.4]'
      )}
      courseSlug={slug}
      byline={
        styleVariant === 'default' && (
          <CourseByline
            course={course}
            courseSession={courseSession}
            showSessionHostInByline={showSessionHostInByline}
            variant={variant}
          />
        )
      }
      body={description}
      thumbnail={customThumbnail || <Thumbnail experts={thumbnailExperts} />}
      horizontalThumbnail={<CourseHorizontalThumbnail experts={thumbnailExperts} />}
      verticalThumbnail={
        <CourseVerticalThumbnail
          course={course}
          courseSession={courseSession}
          styleVariant={styleVariant}
          experts={thumbnailExperts}
        />
      }
      footer={FooterComponent({ onCoursesIndexPage })}
      destination={destinationUrl}
      trackCardClick={handleTracking}
      bookmark={bookmark}
      currentFolder={currentFolder}
      bookmarkFolders={bookmarkFolders}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      restoreBookmark={restoreBookmark}
      handleRemoveFromFolder={handleRemoveFromFolder}
      hideBookmarkButton={hideBookmarkButton}
      customHoverMiniCard={customHoverMiniCard}
      impressionTrackingProperties={impressionTrackingProperties}
      bookmarkDropdownPosition={bookmarkDropdownPosition}
    />
  )
}

const CourseByline = ({
  course,
  courseSession,
  showSessionHostInByline,
  variant
}: {
  course: Omit<CclCourseCourseCardPartsFragment, 'staticId' | '__typename'>
  showSessionHostInByline: Boolean
  courseSession?: CclCourseSessionCourseCardPartsFragment | null
  variant?: CardVariant
}) => {
  const { showCourseLandingPageUpdates } = useFeatureFlags()
  const experts: CclExpertCourseCardPartsFragment[] =
    (showSessionHostInByline ? courseSession?.experts : course.creators) || []

  const expertNames = experts.map((expert) => expert.name)

  const expertFacepileUsers = experts.map((expert) => {
    return {
      id: expert.id,
      avatarUrl: expert.avatarUrl || ''
    }
  })

  if (experts.length > 0) {
    if (variant === CardVariants.List) {
      return (
        <div className="flex gap-2">
          <div className="flex shrink-0 grow-0">
            <FacePile users={expertFacepileUsers} imageSize="small" />
          </div>
          <div className="text-ellipsis line-clamp-1">{listify(expertNames)}</div>
        </div>
      )
    } else {
      if (showCourseLandingPageUpdates) {
        return <span className="text-xs leading-[1.5]">{listify(expertNames)}</span>
      }

      return (
        <span>
          {`${showSessionHostInByline ? 'Hosted' : 'Created'} by ${listify(expertNames)}`}
        </span>
      )
    }
  }

  expertNames.length > 0 ? '' : null
  return null
}

const CourseFooter = ({
  course,
  courseSession,
  timezone,
  showFreeWithCoursePass = false,
  onCoursesIndexPage = false
}: {
  course: Omit<CclCourseCourseCardPartsFragment, 'staticId' | '__typename'>
  courseSession?: CclCourseSessionCourseCardPartsFragment | null
  timezone?: string | null
  showFreeWithCoursePass?: boolean
  onCoursesIndexPage?: boolean
}) => {
  const { showCourseLandingPageUpdates } = useFeatureFlags()

  if (!courseSession) {
    return null
  }

  const { startsAt, endsAt, id } = courseSession

  if (!startsAt) {
    return null
  }

  try {
    const formattedStartDate = formatInTimeZone(
      startsAt,
      getCurrentTimezone(timezone),
      'MMM d'
    )

    if (isBeforeDate(courseSession.startsAt)) {
      const durationText = courseSession.duration

      if (!durationText && !formattedStartDate) return null

      if (showCourseLandingPageUpdates && onCoursesIndexPage) {
        return (
          <div className="flex items-center">
            <span className="text-xs leading-[1.5] text-rb-gray-400 mr-4 text-ellipsis overflow-hidden">
              {durationText}
            </span>

            {course.onDemand ? (
              <Tag
                text="Live course available"
                variant="light"
                className="mr-1 font-semibold"
              />
            ) : (
              <Tag
                text="Live only course"
                variant="orange"
                className="mr-1 font-semibold"
              />
            )}
            <Tag
              text={`Starts ${formattedStartDate}`}
              variant="light"
              className=" font-semibold"
            />
          </div>
        )
      }

      return (
        <div className="flex flex-row justify-between sm:mr-8">
          <div>
            <span className="font-semibold text-rb-gray-400">
              Starts {formattedStartDate}
            </span>
            {durationText && (
              <>
                <span className="px-1">·</span>
                <span>{durationText}</span>
              </>
            )}
          </div>
          {showFreeWithCoursePass && (
            <RfParagraphMiniSemiBold className="text-green-600">
              Free with your Course Pass
            </RfParagraphMiniSemiBold>
          )}
        </div>
      )
    }
    if (!endsAt) {
      return null
    }
    const formattedEndDate = formatInTimeZone(
      endsAt,
      getCurrentTimezone(timezone),
      'MMM d, yyyy'
    )
    return (
      <div className="flex flex-row justify-between sm:mr-8">
        <span className="font-semibold">
          {formattedStartDate} - {formattedEndDate}
        </span>
        {showFreeWithCoursePass && (
          <RfParagraphMiniSemiBold className="text-green-600">
            Free with your Course Pass
          </RfParagraphMiniSemiBold>
        )}
      </div>
    )
  } catch (e) {
    notifyError(
      `ccl_course_session id: ${id} - starts_at: ${startsAt}, ends_at: ${endsAt}: ${e}`
    )
    return null
  }
}

const CourseVerticalThumbnail = ({
  experts,
  styleVariant,
  course,
  courseSession
}: {
  course: any
  courseSession?: CclCourseSessionCourseCardPartsFragment | null
  styleVariant: string
  experts?: CclExpertCourseCardPartsFragment[] | null
}) => {
  const { pathname } = useLocation()
  const { isLoggedIn } = useCurrentUser()
  const expertImageData = expertsToImageData(experts)
  const { currentUser } = useCurrentUser()

  if (expertImageData.length === 0) return null
  if (styleVariant === 'live-featured') {
    const session = course.nextLiveSession || courseSession
    const formattedStartDate = formatInTimeZone(
      session?.startsAt,
      getCurrentTimezone(currentUser?.timezone),
      'MMM d'
    )
    const formattedEndDate = formatInTimeZone(
      session?.endsAt,
      getCurrentTimezone(currentUser?.timezone),
      'MMM d'
    )
    const facepileUsers = experts
      ?.filter((author) => author && author?.avatarUrl)
      .map((author) => {
        return {
          id: author.id,
          avatarUrl: author?.avatarUrl
        } as FacePileUser
      })

    const authorNames = experts
      ?.filter((author) => author && author.name)
      .map((author) => author.name) as string[]

    return (
      <div className="text-white bg-rb-black h-full flex flex-col md:flex-row md:justify-between md:items-center gap-2 p-8">
        <div className="flex flex-col justify-between h-full min-w-[155px]">
          <div className="flex flex-col gap-4">
            {!isLoggedIn && pathname === '/' && (
              <CourseAvailabilityBadge title="Live" className="bg-rb-green-75" />
            )}
            <CourseDetailEnrollmentBadges
              isSessionEnrollable={session.isDuringEnrollmentPeriod}
              courseSession={session}
            />

            <div className="flex font-sans text-base md:text-xl font-semibold">{`${formattedStartDate} - ${formattedEndDate}`}</div>
          </div>

          <div className="flex flex-col ">
            <span className="text-xs md:text-sm">hosted by</span>
            <div className="flex gap-2 relative md:hidden mt-2">
              <div className="flex shrink-0 grow-0">
                <FacePile users={facepileUsers} imageSize="small" />
              </div>
              <div className={cn('text-ellipsis line-clamp-1')}>
                {listify(authorNames)}
              </div>
            </div>
            <span className="hidden md:block font-sans font-semibold">
              {experts
                ?.slice(0, 2)
                .map((expert, index, array) =>
                  index === array.length - 1 ? expert.name : `${expert.name} and `
                )}
            </span>
          </div>
        </div>

        <div className="hidden md:flex max-w-[175px] flex-row w-full h-full gap-2">
          {expertImageData.slice(0, 3).map((expert, idx) => (
            <Image
              key={idx}
              className={cn(
                'h-full w-full rounded-xl object-cover',
                expertImageData.length >= 2 && 'w-1/2',
                expertImageData.length >= 3 && 'w-1/3'
              )}
              src={expert.url}
              alt={expert.name}
            />
          ))}
        </div>
      </div>
    )
  }

  if (styleVariant === 'on-demand-featured') {
    const facepileUsers = experts
      ?.filter((author) => author && author?.avatarUrl)
      .map((author) => {
        return {
          id: author.id,
          avatarUrl: author?.avatarUrl
        } as FacePileUser
      })

    const authorNames = experts
      ?.filter((author) => author && author.name)
      .map((author) => author.name) as string[]

    return (
      <div className="text-rb-gray-400 bg-rb-orange-25 h-full flex flex-col md:flex-row md:justify-between md:items-center gap-2 p-8">
        <div className="flex flex-col justify-between h-full min-w-[155px]">
          <div className="flex flex-col gap-4">
            <CourseAvailabilityBadge title="On-demand" className="bg-rb-orange" />
          </div>

          <div className="flex flex-col">
            <span className="text-xs md:text-sm">created by</span>
            <div className="flex gap-2 relative md:hidden mt-2">
              <div className="flex shrink-0">
                <FacePile users={facepileUsers} imageSize="small" />
              </div>
              <div className="text-ellipsis line-clamp-1">{listify(authorNames)}</div>
            </div>
            <span className="hidden md:block font-sans font-semibold">
              {experts
                ?.slice(0, 2)
                .map((expert, index, array) =>
                  index === array.length - 1 ? expert.name : `${expert.name} and `
                )}
            </span>
          </div>
        </div>

        <div className="hidden md:flex max-w-[175px] flex-row w-full h-full gap-2">
          {expertImageData.slice(0, 3).map((expert, idx) => (
            <Image
              key={idx}
              className={cn(
                'h-full w-full rounded-xl object-cover',
                expertImageData.length >= 2 && 'w-1/2',
                expertImageData.length >= 3 && 'w-1/3'
              )}
              src={expert.url}
              alt={expert.name}
            />
          ))}
        </div>
      </div>
    )
  }

  if (expertImageData.length <= 2) {
    return (
      <div className="flex h-full gap-x-2 px-4 pt-4">
        {expertImageData.map((expert, idx) => (
          <div
            key={`card-thumbnail-${idx}`}
            className="h-full w-[calc(50%-4px)] flex-none rounded-xl"
          >
            <Image
              className="h-full w-full rounded-xl object-cover"
              src={expert.url}
              alt={expert.name}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={'h-full px-4 pt-4'}>
      <div className="relative flex h-full gap-x-2 overflow-hidden">
        {expertImageData.length > 3 && (
          <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
            +{expertImageData.length - 3}
          </div>
        )}
        {expertImageData.slice(0, 3).map((expert, idx) => (
          <div
            key={`card-thumbnail-${idx}`}
            className="h-full w-[calc(33.3%-5.3px)] flex-none rounded-xl"
          >
            <Image
              className="h-full w-full rounded-xl object-cover"
              src={expert.url}
              alt={expert.name}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const expertsToImageData = (experts?: CclExpertCourseCardPartsFragment[] | null) => {
  return (
    experts?.map((expert) => {
      return {
        url: expert.avatarUrl || '',
        name: expert.name
      }
    }) || []
  )
}

const CourseHorizontalThumbnail = ({
  experts
}: {
  experts?: CclExpertCourseCardPartsFragment[] | null
}) => {
  if (!experts) {
    return null
  }
  const expertsImageData = expertsToImageData(experts)
  if (expertsImageData.length === 0) return null

  if (expertsImageData.length === 1) {
    return (
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={expertsImageData[0].url || ''}
        alt={expertsImageData[0].name}
      />
    )
  }
  return (
    <div className="relative flex h-full gap-x-2">
      {expertsImageData.length > 2 && (
        <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{expertsImageData.length - 2}
        </div>
      )}
      {expertsImageData.slice(0, 2).map((expertData, idx) => (
        <div
          key={`card-thumbnail-${idx}`}
          className="h-full w-[calc(50%-4px)] flex-none rounded-xl"
        >
          <Image
            className="h-full w-full rounded-xl object-cover"
            src={expertData.url || ''}
            alt={expertData.name}
          />
        </div>
      ))}
    </div>
  )
}

const Thumbnail = ({
  experts
}: {
  experts?: CclExpertCourseCardPartsFragment[] | null
}) => {
  const expertsImageData = expertsToImageData(experts)
  if (expertsImageData.length === 0) return null

  return (
    <div className="relative">
      {expertsImageData.length > 1 && (
        <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{expertsImageData.length - 1}
        </div>
      )}
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={expertsImageData[0].url}
        alt={expertsImageData[0].name}
      />
    </div>
  )
}

export default CourseCard
