import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  WithStore
} from 'pure-react-carousel'
import {
  Fragment,
  Ref,
  RefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { Tag } from 'components/Tag'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  Collection,
  ProgramBookmarkPartsFragment,
  SavedArtifactsAndGuidesForUserQuery
} from 'gql'

import useContainerQuery from 'hooks/useContainerQuery'
import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import { ReactComponent as ChevronLeftIcon } from 'images/icon--thin-chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/icon--thin-chevron-right.svg'

import FeedItem from './FeedItem'

// Standard container breakpoints
const ONE_SLIDE_MAX_WIDTH = 592 // px
const THREE_SLIDE_MIN_WIDTH = 848 // px
const FOUR_SLIDE_MIN_WIDTH = 1181 // px

// Live Course container breakpoints
const LIVE_COURSE_FOUR_SLIDE_MIN_WIDTH = 9999 // px

interface SwimlaneCarouselProps {
  recommendedCollection: Collection
  savedData?: SavedArtifactsAndGuidesForUserQuery
  pageLocation: string
  index: number
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  highlighted?: boolean
  useMobileView?: boolean
}

interface SwimlaneCarouselContainerState {
  currentSlide: number
  totalSlides: number
  visibleSlides: number
}

export const SwimlaneCarousel = ({
  recommendedCollection,
  savedData,
  pageLocation,
  index,
  highlighted,
  openAddToBookmarkFolderModal,
  useMobileView = false
}: SwimlaneCarouselProps) => {
  const { currentUser } = useCurrentUser()
  const isLiveRecs = recommendedCollection.listType === 101
  const isOnDemandRecs = recommendedCollection.listType === 1

  const isMobileViewport = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const [params, containerRef] = useContainerQuery({
    isMobileView: {
      maxWidth: ONE_SLIDE_MAX_WIDTH
    },
    threeSlides: {
      minWidth: THREE_SLIDE_MIN_WIDTH
    },
    fourSlides: {
      minWidth:
        isLiveRecs || (isOnDemandRecs && currentUser?.is.freeUser)
          ? LIVE_COURSE_FOUR_SLIDE_MIN_WIDTH
          : FOUR_SLIDE_MIN_WIDTH
    }
  })

  const slideCount = useSwimlanesSlidesCount(params)
  const visibleSlides = useMobileView ? 1 : slideCount

  const slideData = useMemo(() => {
    const items = recommendedCollection.items.filter((course) => {
      if (isLiveRecs) {
        return course.live === true && course.nextLiveSession !== null
      } else {
        return recommendedCollection.items
      }
    })
    return { ...recommendedCollection, items }
  }, [isLiveRecs, recommendedCollection])

  const minCardWidth = useMemo(() => {
    const standardCardMinWidth = 280
    const liveCourseCardMinWidth = 381

    if (isMobileViewport) return 280
    if (isLiveRecs) return liveCourseCardMinWidth
    return standardCardMinWidth
  }, [isMobileViewport, isLiveRecs])

  return (
    <CarouselProvider
      naturalSlideWidth={400}
      naturalSlideHeight={400}
      totalSlides={
        params.isMobileView
          ? recommendedCollection.items.length + 1
          : recommendedCollection.items.length
      } // +1 for the empty element
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      className="w-full"
    >
      <CarouselWithStore
        containerRef={containerRef}
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        visibleSlides={visibleSlides}
        minCardWidth={minCardWidth}
        recommendedCollection={slideData}
        savedData={savedData}
        pageLocation={pageLocation}
        highlighted={highlighted}
        index={index}
      />
    </CarouselProvider>
  )
}

interface CarouselProps {
  containerRef: Ref<HTMLDivElement>
  visibleSlides: number
  recommendedCollection: Collection
  savedData?: SavedArtifactsAndGuidesForUserQuery
  pageLocation: string
  index: number
  minCardWidth?: number
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  highlighted?: boolean
  useMobileView?: boolean
}

const BUTTON_STYLINGS = 'text-rb-black flex items-center justify-center'

const Carousel = forwardRef(
  (
    {
      containerRef,
      recommendedCollection,
      pageLocation,
      openAddToBookmarkFolderModal,
      visibleSlides,
      currentSlide,
      savedData,
      index,
      minCardWidth = 272,
      highlighted = false,
      useMobileView = false
    }: CarouselProps & SwimlaneCarouselContainerState,
    // Required otherwise there will be an error in console.
    ref: RefObject<HTMLDivElement> // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    const carouselRef = useRef<HTMLDivElement>(null) // cannot use containerRef. It will always be undefined in this component
    const [slideWidth, setSlideWidth] = useState(0)
    const isMobileView = visibleSlides === 1 || useMobileView
    const gapWidth = 24
    const containerWidth = document.body.offsetWidth - 16 * 2 // remove the left/right content padding - only used on mobile view
    const totalSlidesWidth = slideWidth * visibleSlides
    const totalGapWidth = gapWidth * visibleSlides
    const sliderWidth = totalSlidesWidth + totalGapWidth
    const emptyElementWidth = isMobileView
      ? slideWidth - Math.abs(containerWidth - sliderWidth) + 2 * gapWidth
      : 0
    const isLastSlideVisible =
      recommendedCollection.items.length - visibleSlides === currentSlide
    const isFirstSlideVisible = currentSlide === 0
    const isLiveCourseSection = recommendedCollection.listType === 101

    const isDraftBeta = recommendedCollection.listType === 100

    const preprocessCollection = () => {
      if (isLiveCourseSection) {
        return recommendedCollection.items.filter(
          (course) => course.live === true && course.nextLiveSession !== null
        )
      }

      return recommendedCollection.items
    }

    useEffect(() => {
      document.body.classList.add('overscroll-x-none')

      return () => {
        document.body.classList.remove('overscroll-x-none')
      }
    }, [])

    useEffect(() => {
      const container = carouselRef.current
      const onResize = () => {
        if (isMobileView) {
          const containerWidth =
            (container?.offsetWidth ?? document.body.offsetWidth) - 16 * 2
          const newSlideWidth = Math.max(
            containerWidth * 0.1 + gapWidth,
            minCardWidth + gapWidth
          )
          setSlideWidth(newSlideWidth)
        }
      }
      window.addEventListener('resize', onResize)

      onResize()

      return () => {
        window.removeEventListener('resize', onResize)
      }
    }, [isMobileView, minCardWidth])

    return (
      <div ref={containerRef} className={`${highlighted ? 'pt-8 pb-12' : 'pb-12'}`}>
        <div
          className={cn(
            'flex flex-col gap-6',
            highlighted && 'bg-rb-orange-25 pb-8 pt-4 rounded-xl mx-[-24px] px-6'
          )}
        >
          <div className="@container relative w-full overflow-x-hidden min-w-0">
            <div className="mb-4 h-8 flex items-center justify-between">
              <div className="flex items-center gap-4 min-w-0">
                <div
                  className={cn(
                    'min-w-0 w-full text-xl font-medium truncate',
                    isLiveCourseSection && 'font-sans'
                  )}
                >
                  {recommendedCollection.title}
                </div>
                {isDraftBeta && (
                  <Tag
                    text="Beta"
                    className="mx-2 cursor-default bg-[rgba(8,10,10,0.10)] text-rb-gray-300"
                  />
                )}
              </div>
              <div
                className={cn('hidden justify-end gap-2 pr-6 md:flex', {
                  'md:hidden': recommendedCollection.items.length === visibleSlides
                })}
              >
                <ButtonBack
                  className={cn(BUTTON_STYLINGS, {
                    'cursor-default ': isFirstSlideVisible
                  })}
                  disabled={isFirstSlideVisible}
                >
                  <ChevronLeftIcon
                    className={cn('h-4 w-4 text-rb-black', {
                      'text-rb-gray-100': isFirstSlideVisible
                    })}
                    fill="currentColor"
                  />
                </ButtonBack>
                <ButtonNext
                  className={cn(BUTTON_STYLINGS, {
                    'cursor-default': isLastSlideVisible
                  })}
                  disabled={isLastSlideVisible}
                >
                  <ChevronRightIcon
                    className={cn('h-4 w-4 text-rb-black', {
                      'text-rb-gray-100': isLastSlideVisible
                    })}
                    fill="currentColor"
                  />
                </ButtonNext>
              </div>
            </div>

            <Slider
              style={{
                width: isMobileView ? `${totalSlidesWidth}px` : null
              }}
              classNameAnimation="transition-transform"
              trayProps={{
                draggable: true
              }}
            >
              {preprocessCollection().map((recommendedItem, i) => {
                return (
                  <Fragment key={i}>
                    {/* Hack - we render the below div with changing width to mimic behavior of homepage UiKit carousel: */}
                    {/* - sticking the last Slide to the right edge of the container when it becomes the active slide */}
                    {i === recommendedCollection.items.length - visibleSlides && (
                      <div
                        className="h-[1px] transition-width"
                        style={{
                          width:
                            isMobileView && currentSlide >= i + 1
                              ? `${emptyElementWidth}px`
                              : 0
                        }}
                      />
                    )}

                    <Slide
                      index={i}
                      key={i}
                      innerClassName="flex h-full"
                      style={
                        isMobileView
                          ? { paddingRight: gapWidth, width: slideWidth }
                          : { paddingRight: gapWidth }
                      }
                    >
                      <FeedItem
                        pageLocation={pageLocation}
                        recommendedCollectionId={recommendedCollection.id}
                        feedItem={recommendedItem}
                        index={i}
                        sectionIndex={index}
                        sectionTitle={recommendedCollection.title}
                        savedData={savedData}
                        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                      />
                    </Slide>
                  </Fragment>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    )
  }
)

Carousel.displayName = 'SwimlaneCarousel'

const CarouselWithStore = WithStore<CarouselProps, SwimlaneCarouselContainerState>(
  Carousel,
  (state) => ({
    currentSlide: state.currentSlide,
    totalSlides: state.totalSlides,
    visibleSlides: state.visibleSlides
  })
)

const useSwimlanesSlidesCount = (params: { [key: string]: boolean }) => {
  const secondSlide = !params.isMobileView
  const thirdSlide = params.threeSlides
  const fourthSlide = params.fourSlides

  return [fourthSlide, thirdSlide, secondSlide, true].filter((slide) => slide).length
}

export default SwimlaneCarousel
