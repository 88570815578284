import React from 'react'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'

import {
  Collection,
  ProgramBookmarkPartsFragment,
  SavedArtifactsAndGuidesForUserQuery
} from 'gql'

import FeedHeader from '../FeedHeader'
import Swimlane from './Swimlane'

interface SwimlanesProps {
  setIsFocusUpdate: (isFocusUpdate: boolean) => void
  hasFocusAreas: boolean
  pageLocation: string
  recommendedCollections?: Collection[]
  savedData?: SavedArtifactsAndGuidesForUserQuery
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  startIndex: number
}

const Swimlanes = ({
  setIsFocusUpdate,
  hasFocusAreas,
  recommendedCollections,
  savedData,
  openAddToBookmarkFolderModal,
  pageLocation,
  startIndex
}: SwimlanesProps) => {
  const { isSidePanelChatOpenDelayed } = useGlobalChat()
  return (
    <div className="w-full flex flex-col">
      <FeedHeader setIsFocusUpdate={setIsFocusUpdate} hasFocusAreas={hasFocusAreas} />
      {recommendedCollections?.map((recommendedCollection, i) => (
        <Swimlane
          pageLocation={pageLocation}
          recommendedCollection={recommendedCollection}
          savedData={savedData}
          highlighted={recommendedCollection.title.includes('live course')}
          index={i + startIndex}
          key={recommendedCollection.id}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          useMobileView={isSidePanelChatOpenDelayed}
        />
      ))}
    </div>
  )
}

export default Swimlanes
