import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CourseDetailCourseBlockEventPartsFragment,
  CourseDetailCourseBlockPartsFragment,
  CourseDetailCourseVideoPartsFragment,
  CourseDetailPartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { buildCourseDetailsUrl, wrapInLogin } from 'utils/courseUtils'
import { getDurationInHours } from 'utils/date'
import { trackCtaClicked } from 'utils/tracking/generated/events'

interface CourseDetailCourseBlockEventProps {
  event: CourseDetailCourseBlockEventPartsFragment | CourseDetailCourseVideoPartsFragment
  courseBlock: CourseDetailCourseBlockPartsFragment
  courseDetail: CourseDetailPartsFragment
  showPreview?: boolean
}

const CourseDetailCourseBlockEvent = ({
  event,
  courseBlock,
  courseDetail,
  showPreview = false
}: CourseDetailCourseBlockEventProps) => {
  const history = useHistory()
  const { isLoggedIn } = useCurrentUser()

  if (!event) return null

  const course = courseDetail

  let eventDurationInHours = 0
  if ('startsAt' in event && 'endsAt' in event) {
    eventDurationInHours = getDurationInHours(event.startsAt, event.endsAt)
  }

  const onEventClick = () => {
    if (!course?.slug) return

    trackCtaClicked({
      cta_location: 'course_details_page',
      cta_type: 'button',
      text: 'Watch Recording',
      related_identifiers: {
        ccl_course_id: course?.id,
        course_title: course?.title,
        event_sanity_id: event?.sourceId,
        event_title: event.title,
        content_block_sanity_id: courseBlock.id,
        content_mode: 'preview'
      }
    })

    const destination = buildCourseDetailsUrl({
      courseSlug: course?.slug || '',
      eventId: event.sourceId
    })

    const wrappedDestination = wrapInLogin(destination, isLoggedIn)

    history.push(wrappedDestination, {
      backTo: [{ label: 'Course Home', path: window.location.pathname }],
      fromCourse: true
    })
  }

  return (
    <div className="flex flex-grow items-center justify-between gap-6">
      <div className="flex items-center">
        <div className="relative mr-6 flex h-[50px] min-w-[50px] items-center justify-center rounded-2xl border border-rb-gray-100 group-hover:bg-rb-orange-25">
          <SVGIcon width="16" height="16" name="events" fill="#080A0A" />
        </div>
        <div className="flex flex-col gap-1">
          <RfParagraphSmall>
            <span className="text-rb-gray-400 line-clamp-2">{event.title}</span>
          </RfParagraphSmall>
          <div className="flex items-center justify-between gap-6">
            {eventDurationInHours > 0 && (
              <RfParagraphMini>
                <span className="text-rb-gray-300">{eventDurationInHours} hr live</span>
              </RfParagraphMini>
            )}
            {showPreview && (
              <Button
                variant="fill"
                color="teal"
                size="x-small"
                className="block border-none px-2.5 py-1 font-normal sm:hidden"
                onClick={onEventClick}
              >
                Preview
              </Button>
            )}
          </div>
        </div>
      </div>
      {showPreview && (
        <Button
          variant="fill"
          color="teal"
          size="x-small"
          className="hidden border-none px-2.5 py-1.5 font-normal sm:block"
          onClick={onEventClick}
        >
          Preview
        </Button>
      )}
    </div>
  )
}

export default CourseDetailCourseBlockEvent
