import { useHistory, useLocation } from 'react-router-dom'

import Button from 'components/Button'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CourseDetailCourseBlockGuidePartsFragment,
  CourseDetailCourseBlockPartsFragment,
  CourseDetailPartsFragment,
  useTrackServerEventMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { buildCoursePreviewLink, wrapInLogin } from 'utils/courseUtils'
import { getAnonymousId } from 'utils/tracking/segment'

import { ReactComponent as MapIcon } from 'images/map.svg'

interface CourseDetailCourseBlockGuidesProps {
  guide: CourseDetailCourseBlockGuidePartsFragment
  courseBlock: CourseDetailCourseBlockPartsFragment
  courseDetail: CourseDetailPartsFragment
  guideIndex: number
}

const CourseDetailCourseBlockGuide = ({
  guide,
  courseBlock,
  guideIndex,
  courseDetail
}: CourseDetailCourseBlockGuidesProps) => {
  const { isLoggedIn } = useCurrentUser()
  const history = useHistory()
  const [trackServerEvent] = useTrackServerEventMutation()
  const { pathname } = useLocation()

  if (!guide) return null

  const course = courseDetail

  const onGuideClick = () => {
    if (!guide.freePreview) return

    const destination = buildCoursePreviewLink({
      courseSlug: course?.slug || '',
      guideSlug: guide.slug
    })

    const wrappedDestination = wrapInLogin(destination, isLoggedIn)

    trackServerEvent({
      variables: {
        input: {
          event: 'Content Clicked - Server',
          anonymousId: getAnonymousId(),
          properties: {
            content_type: 'guide',
            content_title: guide.title,
            content_sanity_id: guide.sourceId,
            location: 'course_details_page',
            display_type: 'thumbnail',
            content_ccl_local_id: guide.id,
            destination: wrappedDestination,
            referrer: pathname,
            relatedIdentifiers: {
              ccl_course_id: course?.id,
              course_sanity_id: courseDetail?.sourceId,
              course_title: course?.title,
              content_block_id: courseBlock.id,
              content_block_title: courseBlock.title,
              position: (guide.displayOrder || 0) + 1,
              content_mode: 'preview'
            }
          }
        }
      }
    })

    history.push(wrappedDestination, {
      backTo: [{ label: 'Course Home', path: window.location.pathname }],
      fromCourse: true
    })
  }

  return (
    <div className="flex flex-grow items-center justify-between gap-6">
      <div className="flex items-center">
        <div className="relative mr-6 flex h-[50px] min-w-[50px] items-center justify-center rounded-2xl border border-rb-gray-100 group-hover:bg-rb-orange-25">
          <MapIcon className="h-[16px] w-[16px] text-rb-gray-500" />
          {guideIndex !== undefined &&
            courseBlock?.guides?.length !== undefined &&
            guideIndex !== courseBlock.guides.length - 1 && (
              <div className="absolute left-[25px] top-[50px] h-full w-0.5 bg-rb-gray-50" />
            )}
        </div>
        <div className="flex w-full flex-col gap-1">
          <RfParagraphSmall>
            <span className="text-rb-gray-400 line-clamp-2">{guide.title}</span>
          </RfParagraphSmall>

          <div className="flex items-center justify-between gap-6">
            {guide.estimatedMinutes && (
              <RfParagraphMini>
                <span className="text-rb-gray-300">
                  {guide.estimatedMinutes} min read
                </span>
              </RfParagraphMini>
            )}

            {guide.freePreview && (
              <Button
                variant="fill"
                color="teal"
                size="x-small"
                className="block border-none px-2.5 py-1 font-normal sm:hidden"
                onClick={onGuideClick}
              >
                Preview
              </Button>
            )}
          </div>
        </div>
      </div>

      {guide.freePreview && (
        <Button
          variant="fill"
          color="teal"
          size="x-small"
          className="hidden border-none px-2.5 py-1.5 font-normal sm:block"
          onClick={onGuideClick}
        >
          Preview
        </Button>
      )}
    </div>
  )
}

export default CourseDetailCourseBlockGuide
