import { MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import ArtifactCard from 'components/cards/Content/ArtifactCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS,
  COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
} from 'components/cards/Content/CourseCard'
import GuideCard from 'components/cards/Content/GuideCard'

import {
  CclArtifact,
  CclGuide,
  ItemFragment,
  ProgramBookmarkPartsFragment,
  RecommendedItem,
  SavedArtifactsAndGuidesForUserQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

interface FeedItemProps {
  index: number
  savedData?: SavedArtifactsAndGuidesForUserQuery
  feedItem: RecommendedItem
  sectionIndex: number
  sectionTitle: string
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  pageLocation: string
  recommendedCollectionId: string
}

const OnDemandCourseFooter = ({ course }: { course: ItemFragment }) => {
  const history = useHistory()
  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    history.push(`/courses/${course.slug}/on-demand/guides/${course.firstGuideSlug}`)
  }

  return (
    <div className="flex justify-between items-center">
      <span className="text-xs leading-[1.5] text-rb-gray-300">Course</span>
      <Button variant="outline" size="x-small" onClick={onClick} className="h-8 px-6">
        Get started
      </Button>
    </div>
  )
}

const FeedItem = ({
  feedItem,
  index,
  savedData,
  sectionIndex,
  sectionTitle,
  openAddToBookmarkFolderModal,
  pageLocation,
  recommendedCollectionId
}: FeedItemProps) => {
  const { currentUser } = useCurrentUser()
  const { showNewCourseGating } = useFeatureFlags()

  const impressionTrackingProperties = {
    location: pageLocation,
    sectionImpressionIndex: index,
    sectionIndex: sectionIndex,
    section: 'home_feed_recommendation',
    sectionTitle: sectionTitle,
    aiGeneratedRecommendationId: recommendedCollectionId,
    cclLocalId: feedItem.localId,
    cclStaticId: feedItem.staticId,
    cclSyncMapId: feedItem.cclSyncMapId
  }
  const additionalRelatedIdentifiers = {
    section: 'home_feed_recommendation',
    section_title: sectionTitle,
    result_index: index,
    local_id: feedItem.localId,
    static_id: feedItem.staticId,
    sync_map_id: feedItem.cclSyncMapId,
    sanity_id: feedItem.sanityId,
    ai_generated_recommendation_id: recommendedCollectionId
  }

  const isLiveCourseSection =
    sectionTitle === 'Access top experts in tech in an upcoming live course'

  return (
    <>
      {(feedItem.kind === 'artifact' || feedItem.kind === 'draft') && (
        <ArtifactCard
          key={feedItem.localId}
          // ArtifactCard expects Artifact or CclArtifact so pass artifact in structured as CclArtifact
          artifact={
            {
              ...feedItem,
              sourceId: feedItem.sanityId,
              id: feedItem.localId,
              __typename: 'CclArtifact'
            } as CclArtifact
          }
          viewCount={feedItem.viewerCount || 0}
          commentCount={feedItem.commentCount || 0}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          pageLocation={pageLocation}
          additionalRelatedIdentifiers={additionalRelatedIdentifiers}
          impressionTrackingProperties={impressionTrackingProperties}
          bookmark={savedData?.savedArtifactsAndGuidesForUser?.find(
            (bookmark) => bookmark.sanityId === feedItem.sanityId
          )}
          bookmarkDropdownPosition="left"
        />
      )}

      {feedItem.kind === 'course' && (
        <CourseCard
          key={feedItem.localId}
          course={feedItem}
          styleVariant={isLiveCourseSection ? 'live-featured' : 'default'}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          pageLocation={pageLocation}
          additionalRelatedIdentifiers={additionalRelatedIdentifiers}
          destinationType={
            isLiveCourseSection
              ? COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS
              : COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
          }
          impressionTrackingProperties={impressionTrackingProperties}
          hideBookmarkButton={showNewCourseGating ? true : isLiveCourseSection} // previously hidden for live courses, now hiding for all courses with this ticket.
          bookmark={savedData?.savedArtifactsAndGuidesForUser?.find(
            (bookmark) => bookmark.sanityId === feedItem.sanityId
          )}
          CourseFooterOverride={
            currentUser?.is.freeUser && showNewCourseGating
              ? OnDemandCourseFooter
              : undefined
          }
        />
      )}
      {feedItem.kind === 'guide' && (
        <GuideCard
          key={feedItem.localId}
          // GuideCard expects Unit or CclGuide so pass guide in structured as CclGuide
          guide={
            {
              ...feedItem,
              sourceId: feedItem.sanityId,
              id: feedItem.localId,
              displayOrder: 0, // arbitrary value
              publishedAt: '', // arbitrary value
              __typename: 'CclGuide'
            } as CclGuide
          }
          overrideImage={feedItem.thumbnailUrl}
          pageLocation={pageLocation}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          bookmark={savedData?.savedArtifactsAndGuidesForUser?.find(
            (bookmark) => bookmark.sanityId === feedItem.sanityId
          )}
          bookmarkDropdownPosition="left"
          impressionTrackingProperties={impressionTrackingProperties}
          additionalRelatedIdentifiers={additionalRelatedIdentifiers}
        />
      )}
    </>
  )
}
export default FeedItem
