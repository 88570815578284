import { RefObject } from 'react'

import Button from 'components/Button'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useQueryParams } from 'hooks/useQueryParams'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'

import { GeneratingIcon } from '../../../../components/icons/GeneratingIcon'
import { PremadeGoals } from './PremadeGoal'

interface AddGoalPromptProps {
  allSessions: any[] // Replace 'any' with the specific type if available
  generatingGoals: boolean
  prompt: string
  setPrompt: (value: string) => void
  submitPrompt: () => void
  textAreaRef: RefObject<HTMLTextAreaElement>
}

export const AddGoalPrompt = ({
  allSessions,
  generatingGoals,
  prompt,
  setPrompt,
  submitPrompt,
  textAreaRef
}: AddGoalPromptProps) => {
  const { aiLearningGoalsDefaultRecommendations } = useFeatureFlags()
  const { currentUser } = useCurrentUser()
  const { setNewParams } = useQueryParams()

  const handlePremadeGoalSelection = async ({ prompt }: any) => {
    localStorage.setItem('goal-prompt', prompt)
    setNewParams({ step: 'tell-us-more' })
  }

  return (
    <div className="bg-[#EDF5F4] p-8 w-full rounded-xl flex flex-col">
      <div className="font-sans text-xl font-semibold text-rb-black">
        {allSessions?.length ? 'Add a new goal' : 'Add your first goal'}
      </div>
      <div className="px-6 my-6 py-3 border bg-white flex items-center border-rb-gray-100 justify-between w-full rounded-xl">
        {!generatingGoals && (
          <div className="mr-1 flex-1 flex items-center">
            <textarea
              ref={textAreaRef}
              name="prompt"
              onChange={(e) => setPrompt(e.target.value)}
              rows={1}
              defaultValue={prompt} // Use defaultValue instead of value to avoid controlled component behavior
              className={cn(
                'border-0 min-h-6 rounded-lg block text-rb-gray-400 text-sm font-medium px-2 py-1 w-full resize-none',
                'focus:bg-transparent active:bg-transparent focus:outline focus:outline-none active:outline active:outline-none'
              )}
              onKeyUp={onEnterKeyPress(submitPrompt)}
              placeholder="What is a development goal you have for the next few months?"
            />
          </div>
        )}
        {generatingGoals && (
          <div className="flex">
            <GeneratingIcon className="animate-spin mr-1.5" />
            <span className="font-sans font-normal text-rb-black">
              Adding your goal...
            </span>
          </div>
        )}

        <Button
          size="small"
          disabled={!prompt?.length || generatingGoals}
          onClick={submitPrompt}
        >
          Add goal
        </Button>
      </div>

      <div className="font-sans font-semibold text-rb-black">
        {allSessions.length ? 'Or, start from a recommended goal' : 'Recommended for you'}
      </div>

      <PremadeGoals
        args={{
          aiLearningGoalsDefaultRecommendations,
          handlePremadeGoalSelection,
          currentUser
        }}
      />
    </div>
  )
}
